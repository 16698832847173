/* .rightSlide {
  background-color: red;
} */
.rightSlide_head {
  height: 50px;
  background-color: #5b8dd7;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 5px;
}

.rightSlide_form {
  width: 100%;
  margin-top: 10px;
  padding: 10px 20px;
}

.rightSlide_formInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rightSlide_formInput_input {
  /* border: none !important; */
  height: 40px !important;
  width: 100% !important;
  border-radius: 5px !important;
  padding: 0px 10px!important;
  color: black !important;
  border: 1px solid grey !important;
  margin: 5px 0px !important;
  font-size: 16px !important;
}
.rightSlide_formInput_input:placeholder{
  font-size: 14px !important;
  color:grey !important
}

.rightSlide_formSelect {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  padding: 10px 0px;
}
.rightSlide_formSelect select {
  width: 45%;
  background-color: rgba(244, 244, 244, 1);
  border: none;
  border-radius: 5px;
  height: 30px;
  color: rgba(120, 120, 120, 1);
  padding: 5px 10px;
}


.rightSlide_formRadio {
  display: flex;
  height: 100%;
  gap: 20px;
    margin-top: 15px;

}
.rightSlide_formDate {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.rightSlide_formDate input {
  width: 80px;
}



.rightSlide_formCategory {
 margin-top: 20px;
}
.rightSlide_formCategoryCheck {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* .city_select{
  width: 100%;
  background-color: rgba(244, 244, 244, 1);
  border: none;
  border-radius: 5px;
  padding: 10px;
  height: 30px;
  color: rgba(120, 120, 120, 1);
} */

@media screen and (max-width:600px) {
  .rightSlide {
    width: 320px !important
  }
}