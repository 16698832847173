.form {
  height: 600px;
  width: 1000px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background-color: white;
  position: relative;
  border-radius: 15px;
}

.formUpper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
  z-index: 99999;
}

.formInputs {
  display: flex;
  gap: 50px;
  width: 100%;
}

.formInput {
  width: 240px;
}

.formInputs_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fromLower {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.formCategories {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  z-index: 999;
  gap: 5px;
  flex-wrap: wrap;
}


.topRight {
  height: 100%;
  width: 450px;
  position: absolute;
  top: 0;
  right: 0;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  opacity: 0.3;
}

.bottomLeft {
  height: 450px;
  width: 450px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  opacity: 0.3;
}

.genderSelect {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
}

.button {
  margin-top: -17px;
}


@media screen and (max-width:1000px) {
  .form {
    width: 100%;
  }
}

@media screen and (max-width:955px) {
  .form {
    padding: 20px;
    width: 100%;
  }

  .genderSelect {
    width: 100%;
  }

}

@media screen and (max-width:900px) {
  .formInputs {
    gap: 20px;
  }

}

@media screen and (max-width:900px) {
  .formInput {
    width: 220px;
  }

  .genderSelect {
    flex-direction: column;
    align-items: flex-start;
  }

  .topRight {
    height: 250px;
    width: 250px;
  }

  .bottomLeft {
    height: 250px;
    width: 250px;
  }
}

@media screen and (max-width:740px) {
  .formInput {
    width: 190px;
  }

  .formInputs {
    gap: 30px;
  }
}

@media screen and (max-width:660px) {
  .formInput {
    width: 160px;
  }

  .formInputs {
    gap: 20px;
  }

  .form {
    height: 800px;
  }
}

@media screen and (max-width:560px) {
  .formInput {
    width: 140px;
  }

  .formInputs {
    gap: 10px;
  }

}

@media screen and (max-width:500px) {
  .form {
    height: 800px;
  }

  .formInputs {
    flex-wrap: wrap;
  }

  .formInput {
    width: 220px;
  }

  .formInputs_section:last-child {
    display: contents;
  }
}

@media screen and (max-width:480px) {
  .form {
    height: 1000px;
  }

  .formInput {
    width: 100%;
  }

  .formInputs_section {
    width: 100%;
  }

  .formCategories {
    height: 250px;
    gap: 1px;
  }
}