.newsletterCityName {
    height: 100%;
    padding: 20px 0px;
}
.newsletterCityNameHead {
    display: flex;
    justify-content: space-between;
}

.newsletterCityNameTable {
    margin-top: 50px;
}

.newsletterCityEventCreation {
    height: 100%;
    padding: 20px 0px;
}
.newsletterCityEventCreationHead {
    display: flex;
    justify-content: space-between;
}

.newsletterCityEventCreationTable {
    margin-top: 50px;
}

.topButtons {
    display: flex;
    gap: 10px;
}

@media screen and (max-width:500px) {
    .topButtons {
        flex-direction: column;
    }
}
