@import url('https://fonts.googleapis.com/css2?family=Pattaya&display=swap');


/* .main_body {
  width: 100%;
  padding: 10px 190px;
}



@media screen and (max-width: 1200px) {
  .main_body {
    padding: 10px 120px;
  }

}
@media screen and (max-width: 850px) {
  .main_body {
    padding: 10px 20px;
  }
} */



/* Category Heading */

h1 {
  font-family: Playfair Display;
  font-size: 40px;
  font-weight: 600;
  line-height: 120%;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 25px;
  }
}

h2 {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
}


.mailIntro {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.mailIntro_bodyLogo {
  position: absolute !important;
  top: -10px;
  left: 0;
  height: 50px;
  width: 100px;
}


.mailIntro_body {
  display: flex;
  min-height: 350px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-image: url("../../../../public/Assets/Email_Template_2//hero_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 50px 0;
}

.mailIntro_bodyContent {
  border-radius: 10px;
  background-color: rgba(229, 219, 1, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 20px;
}

@media screen and (max-width:600px) {
  .mailIntro_bodyContent {
    padding: 10px;
    width: 90%;
  }
}

.mailIntro_bodyContent div h1,
.mailIntro_bodyContent div p {
  text-align: center;
}

.mailIntro_bodyContent_deafault {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.mailIntro_bodyContent_deafault p:first-child {
  font-family: Playfair Display;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
}

.mailIntro_bodyContent_greeting {
  display: flex;
  flex-direction: column;
}

/* Ad One  */

.adImg {
  border-radius: 7px !important;
}

.mailAd {
  /* height: 140px; */
  width: 100%;
  padding: 20px;
}

/* Sections  */
.mailSections {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  min-height: 550px;
  height: auto;
  gap: 25px;
  margin: 0px 20px;
  border-radius: 20px;
  padding-bottom: 20px;
}

.mailSections_intro_Head {
  font-family: Pattaya !important;
  letter-spacing: 4px;
}

.mailSections_intro {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.mailSections_cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: center; */
  gap: 25px;
  flex-wrap: wrap;
  transition: 0.8s ease-in;
}


.mailSections_cardsDesign {
  min-height: 350px;
  border-radius: 7px;
  height: auto !important;
  width: 100%;
  max-width: 240px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .mailSections_cards {
    grid-template-columns: auto;
    gap: 30px;
  }

  .mailAd {
    padding: 10px 20px;
  }

  .mailSections_cardsDesign {
    max-width: 100%;
  }
}

.mailSections_cardsDesign img {
  /* height: 100%; */
  width: 100%;
}

.mailSections_cardsDesign_Content {
  width: 100%;
  min-height: 80px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mailSections_cardsDesign_Content p {
  font-family: 'Inter', sans-serif !important;
}

.mailSections_cardsDesign_Content_expandedCard {
  display: flex;
  flex-wrap: wrap;
}

.mailSections_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailSections_bottom button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Footer  */
.footer_desc p {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.footerBoy {
  height: 200px;
  width: 150px;
  margin-top: -50px;
}

.footerGirl {
  height: 200px;
  width: 150px;
  margin-top: -50px;
}

.mailFooter_lowerLinks {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.character {
  top: 60px;
}

.mailFooter_upper {
  height: 180px;
}

@media screen and (max-width: 600px) {
  .mailFooter_upper {
    height: 138px;
  }

  .character {
    top: 7px;
  }

  .footerBoy {
    width: 100px;
    height: 125px;
    margin-top: 15px;
  }

  .footerGirl {
    width: 100px;
    height: 125px;
    margin-top: 15px;

  }

  .mailFooter_lowerLinks {
    flex-direction: column;
    align-items: center;
  }
}