.sideBar {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  gap: 15px;
  border-right: 2px solid rgba(149, 149, 149, 0.35);
  align-items: center;
}

.sideBarList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;
}

.sideBarList button {
  width: 100%;
text-align: center;
background-color: transparent;
border: none;
}

.link {
  text-decoration: none;
  color: #959595;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 25px;
}

.logout{
  background-color:var(--primary-blueColor) !important;
  color: white;
  padding:10px 20px;
  font-size: 16px;
  border-radius: 5px;
}


@media screen and (max-width: 1200px ) {
  .sideBar {
    display: none;
  }
}