
.rightSlide_form {
  width: 100%;
  margin-top: 10px;
  padding: 10px 20px;
}

.rightSlide_formInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rightSlide_formInput_input {
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 0px !important;
}

.rightSlide_formSelect {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  padding: 10px 0px;
}
.rightSlide_formSelect select {
  width: 45%;
  background-color: rgba(244, 244, 244, 1);
  border: none;
  border-radius: 5px;
  height: 30px;
  color: rgba(120, 120, 120, 1);
  padding: 5px 10px;
}


.rightSlide_formRadio {
  display: flex;
  height: 100%;
  gap: 20px;
    margin-top: 15px;

}
.rightSlide_formDate {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.rightSlide_formDate input {
  width: 80px;
}



.rightSlide_formCategory {
 margin-top: 20px;
}
.rightSlide_formCategoryCheck {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media screen and (max-width:600px) {
  .rightSlide {
    width: 320px !important
  }
}