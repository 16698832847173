p {
  font-weight: 500;
}
h2 {
  font-size: 25px;
}
.umt {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* .commonGap {
  align-items: center;
} */

.commonGap_input {
  width: 30%;
}



@media screen and (max-width: 600px) {
  .commonGap {
    flex-direction: column;
    align-items: normal;
  }
  .commonGap p {
    justify-content: flex-start !important;
  }
  .commonGap_input {
    width: 100% !important;
  }
  .settingBtn {
    margin: auto;
}
}
