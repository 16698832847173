.categorys {
    height: 100%;
    padding: 20px 0px;
}
.categorysHead {
    display: flex;
    justify-content: space-between;
}
.categorysTable {
    margin-top: 50px;
}
 
@media screen and (max-width:800px) {
    .topButton {
        flex-wrap: wrap !important;
        justify-content: flex-end;
    }
}