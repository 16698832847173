.user {
    height: 100%;
    padding: 20px 0px;
}
.userHead {
    display: flex;
    justify-content: space-between;
}

.userTable {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
}



.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}



