.filterBtn {
    color: var(--primary-blueColor);
}

.addBtn {
    background-color: red;
}

.category_grid {
    display: grid;
    grid-template-columns: auto auto auto;
}


@media screen and (max-width:900px) {
    .category_grid {
        grid-template-columns: auto auto;
    }
}
@media screen and (max-width:600px) {
    .category_grid {
        grid-template-columns: auto;
    }
}