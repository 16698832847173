.staff {
    height: 100%;
    padding: 20px 0px;
}
.staffHead {
    display: flex;
    justify-content: space-between;
}

.staffTable {
    margin-top: 50px;
}