.location {
    height: 100%;
    padding: 20px 0px;
}
.locationHead {
    display: flex;
    justify-content: space-between;
}

.locationTable {
    margin-top: 50px;
}