:root {
  --primary-blueColor: #1976d2;
  --primary-textColor: #959595;
  --table-head: #000000;
  --footer-greyText: #634f4f;
  /* font-family: "Poppins", sans-serif !important; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
h3 {
  /* font-family: "Poppins", sans-serif !important; */
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}
p {
  /* font-family: "Poppins", sans-serif !important; */
  font-size: 14px;
  font-weight: 400 ;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.active {
  border-left: 10px solid var(--primary-blueColor);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: rgba(91, 141, 215, 1) !important;
  padding-left: 15px !important;
  display: flex;
  align-items: center !important;
}
.deleteIcon:hover {
  color: var(--primary-blueColor);
  cursor: pointer;
}
.hoverIcon:hover {
  color: var(--primary-blueColor) !important;
  cursor: pointer !important;
  background-color: white !important;
}

.headDetail {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.totalNumbers {
  color: var(--primary-textColor);
}

.ButtonResponsive {
  width: 100%;
  font-size: 10px;
}

.rightSlide_head {
  height: 60px;
  background-color: var(--primary-blueColor);
  border-radius: 0px 0px 8px 8px;
  padding: 5px;
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}
.rightSlide_head span {
  position: absolute;
  top: 0px;
  left: 0px;
}
.rightSlide_head p {
  font-size: 20px;
font-weight: 500;
margin-bottom: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: white;
}

.footerText {
  color: var(--footer-greyText) !important ;
}

.addBtn:hover {
  background-color: white !important ;
  color: black !important;
  border: 1px solid  var(--primary-blueColor);
}

.drag {
  background-color: #1976d2;
}



@media screen and (max-width:800px) {
  .drag {
    width: 100px !important;
    font-size: 10px !important;
    height: 30px !important;
    white-space: nowrap !important;
  }
}

/* className='buttonHover' */

@media screen and (max-width: 900px) {
  h3 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-jcc {
  justify-content: center;
}

.flex-jfe {
  justify-content: flex-end;
}

.flex-ac {
  align-items: center;
}

.flex-jcse {
  justify-content: space-evenly;
}

.flex-jcsb {
  justify-content: space-between;
}

.flex-aic {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-saround {
  justify-content: space-around;
}
