.history {
    height: 100%;
    padding: 20px 0px;
}
.history_head {
    display: flex;
    justify-content: space-between;
}

.history_table {
    margin-top: 50px;
}

.BackIcon {
 font-size: 40px;
  cursor: pointer;
}
.BackIcon:hover {
 color: var(--primary-blueColor);
}

@media screen and (max-width:800px) {
    
  .BackIcon {
    font-size: 20px;
  }
}