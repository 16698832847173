/* .rightSlide {
  background-color: red;
} */
.rightSlide_head {
  height: 50px;
  background-color: #1976d2;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 5px;
}

.rightSlide_form {
  width: 100%;
  margin-top: 10px;
  padding: 10px 20px;
}

.rightSlide_formInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rightSlide_formInput_input {
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 0px !important;
}

.rightSlide_formDate {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}
.rightSlide_formDate input {
  width: 80px;
}

@media screen and (max-width:600px) {
  .rightSlide {
    width: 320px !important
  }
}

