.main {
    height: 100vh;
    width: 100vw;
    display: flex;
}
.mainPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x:hidden ;
}
.mainPage_content {
    height: 100%;
    width: 100%;
    padding: 0px 50px;
    padding-bottom: 20px !important;
}

@media screen and (max-width: 900px) {
    .mainPage_content {
    padding: 0px 10px;
}
.mainPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* overflow-x: auto ; */
}
}