.grandParent {
    margin-top: 30px;
}
.parent {
    margin-top: 20px;
}
.parentHead {
     width: 100%;
      padding: 10px 20px; 
      text-align: center;
       margin-bottom: 10px; background: rgba(231, 231, 231, 0.7) 
}
.parentRow {
    width: 100%;
     padding: 10px 20px; 
     border-bottom: 2px solid rgba(149, 149, 149, 0.15); 
     text-align: center;
}