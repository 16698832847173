.admin_hireflax_form_container {
    max-height: 100vh;
    overflow-y: hidden;
}

.admin_forimage {
    height: 100vh;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin_login_form {
    height: 100vh;
    width: 40%;
}

.admin_forimage>img {
    width: 60%;
}

.admin_login_heading>span {
    font-size: 30px;
    font-weight: 700;
    color: #1f242e;
}


@media only screen and (max-width: 768px) {

    .admin_forimage {
        display: none !important;
    }

    .admin_login_form {
        min-height: 100vh;
        width: 100%;
    }
    .forgetPass {
        width: 280px !important;
    }
}