.mailPreviewPage {
    height: 100vh;
}
.mailPreviewPage_content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
}
.contentGap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contentGap p {
    font-weight: 500;
}

.topSection {
    margin: 20px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.editCategory {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 40%;
}

@media screen and (max-width:1200px) {
    .mailPreviewPage {
        display: flex;
        flex-direction: column;
    }
    .mailPreviewPage_content {
        width: 100%;
    }
}
@media screen and (max-width:900px) {
    .mailPreviewPage {
        display: flex;
        flex-direction: column;
    }
    .mailPreviewPage_content {
        width: 100%;
    }
    .mailPreviewPage_content {
        width: 100% !important;
    }
}
