.form {
  height: 550px;
  width: 1000px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background-color: white;
  position: relative;
  border-radius: 15px;
}
.formUpper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
}
.formInputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.formInput {
  width: 240px;
}
.formInputs_section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.fromLower {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.formCategories {
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 100%;
  gap: 5px;
  flex-wrap: wrap;
}


.topRight {
     height: 100%;
  width: 450px;
  position: absolute;
  top: 0;
  right: 0;
     background-repeat: no-repeat !important;
    background-size: contain !important;
  opacity: 0.3;
}
.bottomLeft {
  height: 450px;
  width: 450px;
  position: absolute;
  bottom: 0;
  left: 0;
     background-repeat: no-repeat !important;
    background-size: contain !important;
  opacity: 0.3;
}


@media screen and (max-width:1000px) {
   .form {
    width: 100%;
   }
}
@media screen and (max-width:900px) {
   .formInput {
    width: 120px;
   }
}
@media screen and (max-width:740px) {
    .form {
        height: 100%;
    }
   .topRight {
    height: 200px;
    width: 200px;
   }
   .bottomLeft {
    height: 200px;
    width: 200px;
   }
   .formCategories {
    height: 250px;
   }
}
@media screen and (max-width:490px) {
    .form {
        height: 750px;
    }
    .formCategories {
        height: 350px;
    }
    .formInput {
        width: 100px;
    }
}
@media screen and (max-width:400px) {
    .formInput {
        width: 70px;
    }
    .form {
        height: 1058px ;
    }
    .formCategories {
        height: 100%;

    }
}
