.popUp {
  /* display: block; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* gap: 20px; */
  overflow-y: scroll !important;
  width: 900px;
  height: 90%;
}

.popUpHead {
  background-color: var(--primary-blueColor);
  color: white;
  height: 80px;
  width: 100%;
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.popUpHead span {
    display: flex;
 justify-content: flex-start;
 align-items: flex-start;
 margin: 5px 0 0 5px;
 display: inline;
}
.popUpHead h3 {
    width: 100%;
    display: flex;
 justify-content: center;
 align-items: flex-end;
}

.popUpForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  padding: 20px;
}

.popUpForm_mail {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.popUpForm_mail p {
  font-size: 15px;
  font-weight: 500;
}

.popUpForm_permisionsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.popUpForm_permisionsContainer_body {
  display: flex;
  gap: 30px;
}

.popUpForm_permisionsContainer_bodyColumns {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.popUpForm_permisionsContainer_bodyColumns_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

@media screen and (max-width: 900px) {
  /* .modal{
        overflow: scroll;
    } */
  .popUp {
    width: 70%;
    display: block;
    height: 90%;
    overflow-y: scroll !important;
  }
  .popUpForm {
    padding: 0 10px;
  }
  .popUpForm_permisionsContainer_body {
    flex-wrap: wrap;
  }

  .popUpHead {
    position: fixed;
  }
  .popUpForm_mail {
    margin-top: 100px;
  }
}

@media screen and (max-width: 500px) {
  .popUp {
    width: 90%;
  }
  .popUpHead h3 {
    margin-left: -15px;
}
}
