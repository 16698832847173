.adModule {
    height: 100%;
    padding: 20px 0px;
}
.adModule_head {
    display: flex;
    justify-content: space-between;
}

.adModule_table {
    margin-top: 50px;
}



.adCreation {
    height: 100%;
    padding: 20px 0px;
}
.adCreation_head {
    display: flex;
    justify-content: space-between;
}

.adCreation_table {
    margin-top: 50px;
}