body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.buttonHover:hover {
  color: black !important;
  border: 1px  solid #1976d2 !important;
  background-color: #f5f5f5 !important;
}

/* ::-webkit-scrollbar {display:none;} */

.custom-ant-modal-weekendo{
  z-index: 2000 !important;
}
.ant-result{
  text-align: center !important;
}