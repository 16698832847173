.categoryContainer{
   display: flex;
   align-items: center;
   gap:1rem;
   padding: 1.5rem;
   overflow-y: auto; 
   width:100%;
   
}

.categoryContainer::-webkit-scrollbar{
    display: none;
}

.categroyButton{
    border-radius: 10px;
    min-width: max-content;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: var(--primary-blueColor) !important;
    color: white;

}