.rightSlide_head {
  height: 60px;
  background-color: #5b8dd7;
  border-radius: 0px 0px 8px 8px;
  padding: 5px;
}

.rightSlide_head span {
display: flex;
align-items: flex-start;
justify-content: flex-start;
}
.rightSlide_head p {
display: flex;
align-items: flex-end;
justify-content: center;
color: white;
}

.rightSlide_form {
  width: 100%;
  margin-top: 10px;
  padding: 10px 20px;
}

.rightSlide_formInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rightSlide_formInput_input {
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 0px !important;
}

@media screen and (max-width:600px) {
  .rightSlide {
    width: 320px !important
  }
}