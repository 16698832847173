@import url('https://fonts.googleapis.com/css2?family=Miltonian+Tattoo&display=swap');
/* .main_body {
    width: 100%;
    padding: 10px 250px;
  }
  
  
  
  @media screen and (max-width: 1200px) {
    .main_body {
      padding: 10px 150px;
    }
  
  }
  @media screen and (max-width: 850px) {
    .main_body {
      padding: 10px 20px;
    }
  }
   */


/* Category Heading */

h1 {
  font-family: 'Miltonian Tattoo', sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 125%;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 25px;
  }
}

h2 {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
}


.mailIntro {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100%;
}

.mailIntro_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  background-image: url("../../../../public/Assets/Email_Template_3/hero_banner.png");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 300px;
}

.mailIntro_bodyContent {
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 100%;
  padding: 10px;
  gap: 10px;
}

@media screen and (max-width:700px) {
  .mailIntro_body {
    background-size: 100px;
    background-position: top right;
  }

  .mailIntro_bodyContent {
    width: 100%;
  }
}


.mailIntro_bodyContent_deafault {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mailIntro_bodyContent_deafault p:first-child {
  font-family: Playfair Display;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
}

.mailIntro_bodyContent_greeting {
  display: flex;
  flex-direction: column;
}

/* Ad One  */



.mailAd {
  /* height: 140px; */
  width: 100%;
  padding: 20px;
}

/* Sections  */
.mailSections {
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-height: 550px;
  height: auto;
  gap: 35px;
  border-radius: 12px;
}

.mailSections_intro {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.mailSections_cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: center; */
  gap: 25px;
  flex-wrap: wrap;
  transition: 0.8s ease-in;
}

.mailSections_cardsDesign {
  min-height: 350px;
  border-radius: 7px;
  height: auto !important;
  width: 100%;
  max-width: 240px;
  padding: 20px;
  background: rgba(255, 173, 71, 0.32);
  ;
  backdrop-filter: blur(2.4px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .mailSections_cards {
    grid-template-columns: auto;
    gap: 30px;
  }

  .mailAd {
    padding: 10px 5px;
  }

  .mailSections_cardsDesign {
    max-width: 100%;
  }
}

.mailSections_cardsDesign img {
  /* height: 100%; */
  width: 100%;
}

.mailSections_cardsDesign_Content {
  width: 100%;
  min-height: 80px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mailSections_cardsDesign_Content p {
  font-family: 'Inter', sans-serif !important;
}

.mailSections_cardsDesign_Content_expandedCard {
  display: flex;
  flex-wrap: wrap;
}

.happy_people {
  width: 80%;
}

.footer_desc p {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.character {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 37px;
}

.footer_girl,
.footer_boy {
  height: 153px;
}

.footer_logo {
  height: 100px;
}

.mailFooter_lowerLinks {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.mailFooter_upper {
  height: 180px;
}

@media screen and (max-width:668px) {
  .mailFooter_upper {
    height: 129px;
  }

  .happy_people {
    width: 100%;
  }

  .character {
    top: 35px;
  }

  .footer_girl,
  .footer_boy {
    height: 100px;
  }

  .footer_logo {
    height: 75px;
  }

  .mailFooter_lowerLinks {
    flex-direction: column;
    align-items: center;
  }
}

.mailSections_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailSections_bottom button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 550px) {}