.navBar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 50px;
  
}
.navBarRight {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.navBarAccount {
  display: flex;
  gap: 10px;
}
.navBarAccount_detail {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width:1200px) {
  .navBar {
    display: none;
  }
}