.table {
  width: 100%;
  height: 100%;
}

table {
  height: 100%;
  width: 100%;
  border-collapse: collapse;
}

thead {
  background: rgba(231, 231, 231, 0.7);
}

thead tr td:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100% !important;
  height: 100%;
  padding-right: 20px;
}
thead tr td:first-child {
  padding-left: 20px;
}

tbody tr {
  padding: 0px 100px;
  border-bottom: 2px solid rgba(149, 149, 149, 0.15);
}
tbody tr td {
  color: rgba(105, 105, 105, 1);
}
tbody tr td:last-child {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding-right: 20px;
}
tbody tr td:first-child {
  padding-left: 20px;
}

.editIcon:hover {
  color: #5b8dd7;
}
.deleteIcon:hover {
  color: #f25959;
}

.aling:nth-child(4) {
text-align: center;
}

@media screen and (max-width: 1200px) {
  .table {
    overflow-x: scroll !important;
  }
}
@media screen and (max-width: 1201px) {
  td {
    white-space: nowrap;
    padding-right: 20px;
  }
}

@media screen and (max-width: 900px) {
  .table {
    overflow: scroll !important;
  }
}
