.mobile_menu {
  display: none;
}

.active_Nav {
  left: 0% !important;
  transition: all 0.5s ease-in-out;
}

.deactive_Nav {
  left: -100% !important;
  transition: all 0.5s ease-in-out;
}

.main {
  background-color: #fff;
  width: 100vw !important;
  height: 100vh !important;
  /* max-width: 10vh; */
  display: none;
  z-index: 999;
  position: fixed;
  left: -100%;
}

.logo {
  display: flex;
  align-items: center;
  color: #000000;
  text-decoration: none;
  width: 150px;
}

.button {
  font-size: 20px;
  padding: 10px 0px;
  background-color: var(--primary-blueColor);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: all 0.9s;
}
.link {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  display: flex;
  padding: 15px 5px;
  justify-content: space-between;
  text-decoration: none;
  color: #000000;
     font-family: "Poppins", sans-serif !important;
}
.button:hover {
  background-color: var(--primary-blueColor);
}
.container {
  padding: 20px;
  padding-top: 30px;
  width: 100vw;
  height: 100vh;
    display: flex;
  flex-direction: column;
  gap: 20px;
}
.right {
  display: flex;
  align-items: center;
}


.containerAccount {
  display: flex;
  gap: 10px;
}
.containerAccount_detail {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media screen and (max-width: 1200px) {
  .mobile_menu {
    display: flex;
    justify-content: space-between;
    height: 30px;
    background-color: #ffffff;
    padding: 40px 0px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .container {
    height: 100vh;
    width: 100vw;
  }

  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (max-width:1200px) {
    .mobile_menu {
    padding: 40px 50px;
  }
}
@media screen and (max-width:900px) {
    .mobile_menu {
    padding: 40px 10px;
  }
  .button {
    z-index: 1111;
  }
}
