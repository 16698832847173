.tags {
    height: 100%;
    padding: 20px 0px;
}
.tagsHead {
    display: flex;
    justify-content: space-between;
}

.tagsTable {
    margin-top: 50px;
}