@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap'); */

.main_body {
  width: 100%;
  padding: 10px 15%;
  /* background-image: url("/Assets/Email_Template_4/whole_background.png"); */
  background-position: center center;
  background-size: cover;
}




@media screen and (max-width: 1200px) {
  .main_body {
    padding: 10px 200px;
  }

}

@media screen and (max-width: 850px) {
  .main_body {
    padding: 10px 20px;
  }
}



/* Category Heading */

.h1 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 40px;
  font-weight: 600;
  line-height: 110%;
  padding-right: 66px;
  background-image: url("../../../../public/Assets/Email_Template_4/heading_astro.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100px;
}

@media screen and (max-width: 800px) {
  .h1 {
    font-size: 25px;
    min-height: 50px;
  }
}

h2 {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
}


.mailIntro {
  display: flex;
  flex-direction: column;
  background-color: #e0fffe;
  height: 100%;
}

.mailIntro_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  background-image: url("../../../../public/Assets/Email_Template_4/hero_banner.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
}

.mailIntro_bodyContent {
  display: flex;
  flex-direction: column;
  /* width: 45%; */
  height: 100%;
  padding: 10px;
  gap: 10px;
  align-items: center;
}

.mailIntro_bodyContent_deafault {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 85%;
}

.mailIntro_bodyContent_deafault div * {
  text-align: center;
}

.mailIntro_bodyContent_deafault p:first-child {
  font-family: Playfair Display;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
}

.hero_img {
  display: flex;
  justify-content: center;
}

.hero_img img {
  width: 65%;
}

@media screen and (max-width:600px) {
  .hero_img img {
    width: 100%;
  }
}

.mailIntro_bodyContent_greeting {
  display: flex;
  flex-direction: column;
}

/* Ad One  */



.mailAd {
  /* height: 140px; */
  width: 100%;
  padding: 20px;
}

/* Sections  */
.mailSections {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  min-height: 550px;
  height: auto;
  gap: 35px;
  border-radius: 12px;
}

.mailSections_intro {
  display: flex;
  flex-direction: column;
  /* min-height: 75px; */
  height: auto;
  gap: 8px;
  align-items: center;
}

.mailSections_cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: space-between; */
  gap: 30px;
  flex-wrap: wrap;
  transition: 0.8s ease-in;
}

@media screen and (max-width: 650px) {
  .mailSections_cards {
    grid-template-columns: auto;
    gap: 30px;
  }

  .mailAd {
    padding: 10px 5px;
  }
}

.mailSections_cardsDesign {
  min-height: 350px;
  border-radius: 7px;
  height: auto !important;
  width: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.mailSections_cardsDesign img {
  /* height: 100%; */
  width: 100%;
}

.mailSections_cardsDesign_Content {
  width: 100%;
  min-height: 80px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mailSections_cardsDesign_Content p {
  font-family: 'Inter', sans-serif !important;
}

.mailSections_cardsDesign_Content_expandedCard {
  display: flex;
  flex-wrap: wrap;
}

.mailSections_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailSections_bottom button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerGirl,
.footerBoy {
  height: 165px;
}

.footerGirl {
  position: relative;
  top: 0.3em;
}

.footer_desc p {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.character {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 15px;
}

.rocket_img {
  height: 55px;
  width: 55px;
}

.spaceman_img {
  height: 75px;
}

.mailFooter_lowerLinks {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.mailFooter_upper {
  height: 180px;
}

@media screen and (max-width:500px) {
  .mailFooter_upper {
    height: 129px;
  }

  .footerBoy,
  .footerGirl {
    height: 100px;
  }

  .character {
    top: 29px;
  }

  .footerGirl {
    top: 0.3em;
  }

  .rocket_img {
    height: 35px;
    width: 35px;
  }

  .spaceman_img {
    height: 55px;
  }

  .mailFooter_lowerLinks {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 550px) {}

.shareIcon{
  display: none;
}
@media screen and (max-width: 550px) {
  .shareIcon{
    /* display: block; */
    display: none;
  }
}