.dashboard {
  height: 100%;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.dashboardHead {
  display: flex;
  justify-content: space-between;
}

.dashboardFilter {
  display: flex;
  gap: 20px;
  /* width: 40%; */
  width: 50%;
}

.dashboardBoxs {
  display: flex;
  gap: 20px;
}

.Boxs {
  height: 150px;
  width: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  padding: 25px;
}

.Boxs p {
  font-size: 13px;
}

.Boxs p,
.Boxs h3,
.Boxs span {
  color: #262626;
}

.dashboardBody {
  width: 100%;
}

.dashboardBodyAnalytic_no {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 50px;

}

.AnalyticsData_box {
  flex: 1;
}

.dashboardBodyAnalytic_noMail {
  align-self: center;
  width: 60%;
  /* height: 500px;
  background: #ffffff; */
  border: 1px solid #dfdfdf;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dashboardBodyAnalytic_noMailData {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
}

.dashboardBodyAnalytic_noMailData_row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboardBodyAnalytic_noMailData_rowIcon {
  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 30%;
  background-color: lightgreen;
}

.dashboardBodyAnalytic_noMailData p {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Performance Table  */

.dashboardBodyAnalytic_noPerformance {
  align-self: center;
  width: 60%;
  /* height: 500px;
  background: #ffffff; */
  border: 1px solid #dfdfdf;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dashboardBodyAnalytic_noPerformanceData {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
}

.dashboardBodyAnalytic_noPerformanceData_row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboardBodyAnalytic_noPerformanceData_rowIcon {
  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 30%;
  background-color: lightgreen;
}

.dashboardBodyAnalytic_noPerformanceData p {
  width: 100%;
  display: flex;
  justify-content: space-between;
}


@media screen and (max-width:900px) {
  .dashboardFilter {
    gap: 10px;
    width: 50%;
  }

  .dashboardBoxs {
    flex-wrap: wrap;
    justify-content: center;
  }

  .Boxs {
    width: 100%;
  }

  .dashboardBodyAnalytic_no {
    flex-direction: column;
  }

  /* Mail Table  */

  .dashboardBodyAnalytic_noMail {
    width: 100%;
  }

  /* Performance Table  */

  .dashboardBodyAnalytic_noPerformance {
    width: 100%;
  }

}

@media screen and (max-width:400px) {
  .dashboardFilter {
    width: 200px;
  }
}