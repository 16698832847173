@import url('https://fonts.googleapis.com/css2?family=Pattaya&display=swap');

.main_body {
  width: 100%;
  padding: 10px 15%;
}


@media screen and (max-width: 1200px) {
  .main_body {
    padding: 10px 200px;
  }
}

@media screen and (max-width: 850px) {
  .main_body {
    padding: 10px 20px;
  }
}


/* Category Heading */

.h1 {
  font-family: 'Pattaya', sans-serif !important;
  font-size: 40px;
  font-weight: 600;
  line-height: 28px;
}

@media screen and (max-width: 800px) {
  .h1 {
    font-size: 25px;
  }
}

h2 {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
}


.mailIntro {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.mailIntro_bodyLogo {
  position: absolute !important;
  top: -10px;
  left: 0;
  height: 50px;
  width: 100px;
}

.mailIntro_body {
  display: flex;
  min-height: 350px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-image: url("../../../../public/Assets/Email_Template_2//hero_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 50px 0;
}

.mailIntro_bodyContent {
  /* margin-top: -50px;
  margin-left: -35px; */
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(229, 219, 1, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

@media screen and (max-width:600px) {
  .mailIntro_bodyContent {
    padding: 10px;
    width: 90%;
  }
}

.mailIntro_bodyContent div * {
  text-align: center;
}

.mailIntro_bodyContent_deafault {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mailIntro_bodyContent_deafault p:first-child {
  font-family: Playfair Display;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
}

.mailIntro_bodyContent_greeting {
  display: flex;
  flex-direction: column;
}


/* Ad One  */



.mailAd {
  /* height: 140px; */
  width: 100%;
  padding: 20px;
}

/* Sections  */
.mailSections {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  min-height: 550px;
  height: auto;
  gap: 35px;
  border-radius: 10px;
}

.mailSections_intro {
  display: flex;
  flex-direction: column;
  /* min-height: 75px; */
  height: auto;
  gap: 8px;
}

.mailSections_cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: space-between; */
  gap: 30px;
  flex-wrap: wrap;
  transition: 0.8s ease-in;
}

@media screen and (max-width: 650px) {
  .mailSections_cards {
    grid-template-columns: auto;
    gap: 30px;
  }

  .mailAd {
    padding: 10px 5px;
  }
}

.mailSections_cardsDesign {
  min-height: 350px;
  border-radius: 7px;
  height: auto !important;
  width: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.mailSections_cardsDesign img {
  /* height: 100%; */
  width: 100%;
}

.mailSections_cardsDesign_Content {
  width: 100%;
  min-height: 80px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mailSections_cardsDesign_Content p {
  font-family: 'Inter', sans-serif !important;
}

.mailSections_cardsDesign_Content_expandedCard {
  display: flex;
  flex-wrap: wrap;
}

.mailSections_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailSections_bottom button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_desc p {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.character {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: -4px;
}

.footer_girl,
.footer_boy {
  height: 200px;
}

.mailFooter_lowerLinks {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.mailFooter_upper {
  height: 180px;
}

@media screen and (max-width: 550px) {
  .mailFooter_upper {
    height: 129px;
  }

  .character {
    top: 16px;
  }

  .footer_girl,
  .footer_boy {
    height: 124px;
  }

  .mailFooter_lowerLinks {
    flex-direction: column;
    align-items: center;
  }
}


.shareIcon{
  display: none;
}
@media screen and (max-width: 550px) {
  .shareIcon{
    /* display: block; */
    display: none;
  }
}